@include bp(tablet) {
  .element-list {
    .element--reference {
      .url {
        background: url('/static/img/equitest/icon-reference-link-2x.png') no-repeat center right;
        background-size: 26px 22px;
      }
    }

    .element--document {

      .url {
        background: url('/static/img/equitest/icon-document-link-2x.png') no-repeat center right;
        background-size: 21px 26px;
      }
    }
  }
}