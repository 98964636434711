.footer {
  background-color: $et-blue;

  .left {
    margin-top: -$section-padding-phone;
  }
}

.header,
.footer {
  .keywords-wrapper {
    .keywords {
      p {
        font-family: Univers LT Pro, sans-serif;
      }
    }
  }
}

@include bp(tablet) {
  .footer {
    background-color: $et-blue;

    .left {
      max-width: 350px;
      margin-top: -$section-padding-tablet;
      margin-bottom: -$section-padding-tablet;

      border-left: 80px solid $bs-blue-light;

      .logo-container {
        margin-left: -30px;
      }

      .keywords-wrapper {
        margin-left: 30px;
        margin-bottom: 30px;
      }
    }
  }
}

@include bp(desktop) {
  .footer {
    background-color: $et-blue;

    .left {
      max-width: 500px;
      margin-top: -$section-padding-desktop;
      margin-bottom: -$section-padding-tablet;
    }

    .right {
      padding-left: 80px;
      
      .row-top {
        margin-bottom: 35px;
      }
    }
  }
}