.top {
  .main {
    .logo-container {
      height: 100%;

      border-left: 30px solid $bs-blue-light;

      .logo-link {
        margin-left: -10px;
      }
    }
  }
}

@include bp(desktop) {
  .top {
    .main {
      .navigation {
        background-color: $et-white;
      }
    }
  }
}