$et-blue: #3E5796;
$et-blue-middle: #7A93C8;
$et-blue-light: #B1C2E2;
$et-blue-bright: #D9E1F0;

$et-green-highlight: #81A608;

$et-white: #ffffff;
$et-black: #000000;

$primary-color-1: $et-blue;
$primary-color-2: $et-blue-middle;
$primary-color-3: $et-blue-light;
$primary-color-4: $et-blue-bright;
$primary-color-5: $et-green-highlight;

$secondary-color-count: 1;
$secondary-color-1-fill: #ffffff;

$grey-1: $et-white;
$grey-2: $et-black;
