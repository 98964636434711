.top {
  .logo-container {
    height: 100%;
    justify-content: flex-start;
  }

  .main {
    .button--navigation-menu {
      background-color: $et-blue;
    }

    .navigation {
      background-color: $et-blue;

      .navigation-items {
        .menu-item {
          &.active {
            border-color: $et-blue !important;
          }

          &:hover {
            border-color: lighten($et-blue, 5%) !important;
          }
        }
      }
    }
  }
}