.featured_employee {
  .feature_employee_info {
    .xing-link {
      @include bp(tablets-landscape) {
        padding: 12px 12px 12px 48px;
        background: $et-blue-bright url("/static/img/xing-icon-2x.png") no-repeat center left 18px;
        background-size: 22px 25px;
      }
    }

    .linkedin-link {
      @include bp(tablets-landscape) {
        padding: 12px 12px 12px 48px;
        background: $et-blue-bright url("/static/img/linkedin-icon-2x.png") no-repeat center left 18px;
        background-size: 22px 25px;
      }
    }
  }

  .featured_employee_details {

    border-top: none;

    .details-header {
      color: $et-blue;
      border-left: 8px solid $et-blue;
      margin-top: -26px;
      padding-top: 26px;
      margin-left: -30px;
      padding-left: 22px;
    }
  }

}